@use "react-widgets/scss/styles.scss" with (
  $components: (
    "Combobox",
  ),
  //Combobox
  $input-height: 2px,
  $input-color: rgb(113, 113, 113),
  $input-box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0),
  $input-padding-x: 0.75rem,
  $widget-focus-width: 0rem,
  //Focus
  $widget-focus-color: rgba(0, 0, 0, 0),
  $input-focus-border-color: rgba(0, 0, 0, 0),
  $widget-focus-transition: none,
  $input-focus-bg: none,
  //Disabled
  $input-disabled-color: rgb(113, 113, 113),
  // 25% lighter active-bg
  $input-focus-color: transparent,
  $input-border-color: rgba(0, 0, 0, 0)
);

[type="text"] {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 5px !important;
}

.rw-widget-picker.rw-widget-input {
  border-width: 4px;
  background-color: transparent;
}

.rw-state-focus .rw-widget-picker.rw-widget-input {
  outline: transparent;
  border-width: 4px;
  border-radius: 0.375rem;
  border-color: rgba(113, 113, 122, 0.7);
  box-shadow: none;
}

input[type="checkbox"]:checked {
  background-color: #ff6358 !important ;
  border-color: #ff6358 !important;

  &:hover {
    background-color: #ff6358 !important ;
    border-color: #ff6358 !important;
  }
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/** GREASEBOSS TEXT CLASSES **/
.gb-h1 {
  @apply text-3xl;
}

.gb-h2 {
  @apply text-2xl;
}

.gb-h3 {
  @apply text-gray-600 text-xl;
}

.gb-h4 {
  @apply text-gray-600 text-lg;
}

.gb-text-normal {
  @apply text-gray-700;
}

// ***** FORM COMPONENTS *****/
.gb-button {
  @apply inline-flex items-center px-4 py-2 bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest transition ease-in-out duration-150;

  &:active {
    @apply bg-gray-900;
  }

  &:hover {
    @apply bg-gray-700;
  }

  &:focus {
    @apply border-gray-900 outline-none;
  }

  &:disabled {
    @apply bg-gray-500;
  }
}

.gb-button-success {
  @apply inline-flex items-center px-4 py-2 bg-green-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest transition ease-in-out duration-150;

  &:active {
    @apply bg-green-900;
  }

  &:hover {
    @apply bg-green-700;
  }

  &:focus {
    @apply border-green-900 outline-none;
  }

  &:disabled {
    @apply bg-green-500;
  }
}
.gb-button-warning {
  @apply inline-flex items-center px-4 py-2 bg-red-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest transition ease-in-out duration-150;

  &:active {
    @apply bg-red-900;
  }

  &:hover {
    @apply bg-red-700;
  }

  &:focus {
    @apply border-red-900 outline-none;
  }
}

.form-field {
  @apply h-8 px-3 border outline-none w-full relative border-gray-300 focus:border-gray-500 focus:ring focus:ring-gray-700 focus:ring-opacity-50 rounded-md shadow-sm text-black;
}
.text-field {
  @apply px-3 border outline-none w-full relative border-gray-300 focus:border-gray-500 focus:ring focus:ring-gray-700 focus:ring-opacity-50 rounded-md shadow-sm text-black;
}

label {
  @apply block font-medium text-sm text-gray-700;
}

.error-message {
  @apply text-red-700 text-sm block;
}

.table-error-message {
  @apply text-red-700 text-sm block;
}

.gb-overlay {
  @apply fixed w-full h-full top-0 right-0 left-0 bottom-0 bg-gray-500 bg-opacity-50 z-10 cursor-pointer;
}

/** Kendo styling overrider **/
.k-panelbar .k-group > .k-item > .k-link,
.k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
  cursor: pointer !important;
}

.k-animation-container {
  z-index: 20003 !important;
}

// multiselect component scrolling
.k-child-animation-container div.k-treeview.k-treeview-md {
  max-height: 300px !important;
  overflow: auto !important;
}

.k-card .k-card-body {
  padding: 5px 8px;
}

.k-card-title {
  font-size: 22px !important;
}

.k-card-subtitle {
  font-size: 14px !important;
}

.k-grid .k-hierarchy-cell .k-i-plus:before {
  content: "\e005";
}
.k-grid .k-hierarchy-cell .k-i-minus:before {
  content: "\e006";
}

.k-card-title {
  font-size: 30px;
}
@media only screen and (max-width: 890px) {
  .k-card-title {
    font-size: 25px;
  }
}

/** Added to allow drag and drop to work correctly, workaround suggested in Telerik Ticket ID: 1609527 
    Also see https://github.com/telerik/kendo-react/issues/1300 for more info. 
**/
.k-listbox .k-list-scroller .k-list.k-list-md {
  height: auto;
  max-height: 100%;
}

.react-multi-email input {
  font-size: 12px;
}

.title {
  font-size: 2em;
  margin-block-start: 5px;
  margin-block-end: 5px;
  font-weight: normal;
}

.example-container {
  margin: 2 auto;
}

.k-widget.k-window.k-dialog {
  max-height: 75%;
}

span.k-icon.k-i-graph.k-icon-70 {
  font-size: 60px;
}

div.k-tabstrip.k-tabstrip-top
  > div.k-content.k-active
  > div.k-animation-container.k-animation-container-relative {
  width: 100% !important;
  height: 99% !important;
}

div.k-tabstrip.k-tabstrip-top .k-child-animation-container {
  height: 100% !important;
}

div.k-tabstrip.k-tabstrip-top .k-child-animation-container > div {
  height: 100% !important;
}

div.scichart__legend > div {
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 10px !important;
  margin: 3px 0 0 0 !important;
  background-color: transparent !important;
}

.treelist-grid .k-grid tr {
  background: #fafafa !important;
}

.treelist-grid tbody {
  cursor: pointer !important;
}

.treelist-grid .k-grid tr:hover {
  background: #ededed !important;
}

.tabstrip span.k-link {
  font-weight: bold;
}

td:has(> .compliant-icon) {
  text-align: center !important;
}

.extra-filter-button
  button.k-button.k-button-md.k-button-solid.k-button-solid-base.k-icon-button.k-input-button {
  display: none !important;
}

.extra-filter-button
  button.k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md.k-icon-button.k-input-button {
  display: none !important;
}

.extra-filter-button .k-grid.k-grid-md.k-treelist-scrollable {
  border-bottom: none !important;
}

.treelist-container
  .k-splitbar.k-splitbar-horizontal.k-splitbar-draggable-horizontal,
.treelist-container .k-splitbar.k-splitbar-horizontal {
  background-color: #ededed !important;
}

#legend-div-id div {
  background-color: transparent !important;
}

// hide the header row from the treelist
// .treelist-grid thead tr.k-table-row:first-child {
//   display: none !important;
// }

.css-1ind3v2 > *:not(style) ~ *:not(style) {
  margin-inline-start: 0 !important;
}

.manageDialog div.k-window.k-dialog {
  max-height: 70%;
}

div.reservoir-view
  .k-button.k-button-md.k-button-solid.k-button-solid-base.k-rounded-md {
  flex: 0 !important;
}

div.reservoir-view
  .k-actions.k-actions-horizontal.k-window-actions.k-dialog-actions.k-actions-stretched {
  justify-content: flex-end !important;
}

/** Added to allow tooltips to be displayed correctly using icon button **/
/** See https://github.com/telerik/kendo-react/issues/1296 **/
.k-button-icon {
  pointer-events: none;
}

// Added to change the border colour from dialog action bar
.k-actions.k-actions-horizontal.k-window-actions.k-dialog-actions.k-actions-stretched {
  border-color: #ededed;
}

.alert-emails {
  max-height: 62px !important;
  overflow: auto !important;
}

.schedule-emails .react-multi-email {
  max-width: 504px !important;
  max-height: 62px !important;
  overflow: auto !important;
}

.chart-splitter div.k-splitbar.k-splitbar-vertical.k-splitbar-draggable-vertical,
.chart-splitter .k-splitbar.k-splitbar-vertical.k-splitbar-static-vertical {
  background-color: lightgray !important;
}

.chart-splitter div.k-splitbar.k-splitbar-vertical.k-splitbar-draggable-vertical:hover,
.chart-splitter .k-splitbar.k-splitbar-vertical.k-splitbar-static-vertical:hover {
  background-color: gray !important;
}

.k-chip-label {
  padding: 1px 0 !important;
}

//height of the range date picker
span.k-dateinput.k-input.k-input-md.k-input-solid.k-rounded-md {
  height: 28px !important;
}

//changing tile layout from dashboard page
.dashboard-page-tile div.k-tilelayout-item-header.k-card-header {
  display: none !important;
}

.dashboard-page-tile div.scichart__legend div {
  display: flex !important;
  flex-direction: column !important;
}

// Change the background of the tile layout to white
.k-tilelayout {
   background-color: #ffffff !important;
}

//Positioning of the loading spinner in the dashboard
.k-loader.k-loader-md.k-loader-primary.loading-dashboard.k-loader-pulsing-2 {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

// Vertical align the header to the top
.k-grid .k-grid-header .k-table-th {
 vertical-align: top !important;
}